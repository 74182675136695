import React from "react";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo, title, texts }) {
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>

                <div className="z-10 w-4/5 mx-auto h-full flex  items-center md:pb-[80px] md:pt-[80px] pt-[80px] pb-[80px] absolute top-0 justify-start">
                    <div className="md:w-1/2 w-[100%] h-[500px] flex flex-col justify-center items-center relative -mt-10 rounded-xl ">
                        <div className="w-full flex flex-col justify-center items-center relative md:px-4">
                            <h1 className="text-white text-center md:text-start text-[35px] mb-2 md:text-[38px]">
                                {title}
                            </h1>
                            <p className="text-white text-center md:text-start">
                                {texts}
                            </p>

                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Background%2Fgrid_white-1.png?alt=media&token=03e5cc94-d670-44b8-9b42-6392c3b94ba2"
                                alt="shapesgrid-nofound"
                                className="absolute h-[200px] -bottom-20 right-5" />
                        </div>
                        <div className="absolute md:bottom-0 -bottom-20 py-5 md:left-5">
                            <ButtonContent />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
export default HeroVideo;